import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  LoadScript,
} from '@react-google-maps/api';
import mapStyles from './config/mapConfig';
import clusterStyles from './config/clusterStyles';
import styles from './index.module.scss';
import { Option } from '~/common/models';
import { Loading, SvgIcon } from '@ui-elements';
import { Dealer, SuburbModel, MarkerType } from './models';
import { SearchDealerPanel, SelectDealerPanel } from './components';
import digitalData from '~/common/services/data-layer-service';
import { SearchDealerPanelRef } from './components/SearchDealerPanel';

export const FindDealer = () => {
  React.useLayoutEffect(() => {
    digitalData.push({
      event: '_pageLoaded',
    });
  }, []);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAJ-SrDUJYZCpChPN1xqE8MZ13ZA87dCus"
      libraries={['places']}
    >
      <FindDealerContent />
    </LoadScript>
  );
};

const FindDealerContent = () => {
  const [departments, setDepartments] = useState([]);
  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentsFilter, setDepartmentsFilter] = useState('Vehicle Sales');
  const [selectedDepartment, setSelectedDepartment] = useState<Option>({
    label: 'All Departments',
    value: 'All Departments',
  });
  const [selectedDealer, setSelectedDealer] = useState<Dealer | null>(null);
  const [suburb, setSuburb] = useState<SuburbModel | null>(null);

  const searchPanelRef = React.useRef<SearchDealerPanelRef>(null);

  const [sizeFilter, setSizeFilter] = useState(0);
  const [currentPostcode, setCurrentPostcode] = useState<string | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markers, setMarkers] = useState<MarkerType[] | null>([]);

  const pinIcon = '/scripts/ContentMedia/images/map-pin-blue.svg';
  const pinActiveIcon = '/scripts/ContentMedia/images/map-pin-green.svg';

  useEffect(() => {
    setMarkers([]);
    fetchDepartments();
    fetchDealers();
  }, [departmentsFilter]);

  const fetchDepartments = () => {
    setIsLoading(true);
    fetch(`/api/dealers/departments`)
      .then((res) => res.json())
      .then((res) => {
        setDepartments(res);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('error fetching departments', error);
        setIsLoading(false);
      });
  };

  const fetchDealers = () => {
    setIsLoading(true);
    let url;
    if (departmentsFilter.toLowerCase() !== 'all departments') {
      const appendSuburbName = suburb ? `&SuburbName=${suburb.name}` : '';
      const appendPostcode = suburb ? `&PostCode=${suburb.postcode}` : '';
      url = `/api/dealers?ByPma=True&DepartmentName=${departmentsFilter}${appendPostcode}${appendSuburbName}`;
    } else {
      const appendPostcode = suburb ? `&PostCode=${suburb.postcode}` : '';
      url = `/api/dealers?DepartmentName=Vehicle Sales${appendPostcode}`;
    }
    if (sizeFilter > 0) {
      url = `${url}&top=${sizeFilter}`;
    }
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);
        setDealers(res);
        createMarkers(res);
        if (!!suburb && res.length > 0) {
          setDealerAndZoom(res[0]);
        }
      })
      .catch((error) => {
        console.log('An error occurred loading dealers.', error);
        setIsLoading(false);
      });
  };

  const fetchDealersWithSuburb = async (suburbName, postcode) => {
    let url;
    if (!departmentsFilter.toLowerCase().includes('all departments')) {
      const appendSuburbName = !!suburbName ? `&SuburbName=${suburbName}` : '';
      const appendPostcode = `&PostCode=${postcode}`;
      url = `/api/dealers?DepartmentName=${departmentsFilter}${appendPostcode}${appendSuburbName}`;
    } else {
      const appendPostcode = `&PostCode=${postcode}`;
      url = `/api/dealers?DepartmentName=Vehicle Sales${appendPostcode}`;
    }
    if (sizeFilter > 0) {
      url = `${url}&top=${sizeFilter}`;
    }
    try {
      setIsLoading(true);
      const dealer = await fetch(url);
      return dealer;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const setDealerBySuburb = async (suburbDetail) => {
    setCurrentPostcode(() => suburbDetail.postcode);

    const res = await fetchDealersWithSuburb(
      suburbDetail.name,
      suburbDetail.postcode
    );

    const dealerFromSuburb = await res.json();

    if (!!dealerFromSuburb && dealerFromSuburb.length > 0) {
      const subDealer = dealerFromSuburb[0];
      setSuburb(suburbDetail);
      setDealerAndZoom(subDealer);

      digitalData.push({
        event: '_formNavigate',
        form: {
          name: 'find a dealer',
          stage: 'submit_map',
        },
        user: {
          postcode: suburbDetail.postcode,
          preferredDealer: subDealer?.name,
        },
      });
    }
  };

  const getDealersWithPostcode = (postcode) => {
    return new Promise((resolve, reject) => {
      if (!!postcode) {
        setCurrentPostcode(() => postcode);
        fetchDealersWithSuburb('', postcode)
          .then((res) => res.json())
          .then((res) => {
            showDealers(res);
            resolve(null);
          });
      } else {
        reject();
      }
    });
  };

  const setDealerAndZoom = (dealer) => {
    const newMarker = markers?.find(
      (marker: MarkerType) => marker.id === dealer.id
    );
    setSelectedDealer(dealer);
    panToMarker(newMarker?.position);
  };

  const createMarkers = (dealersResponse) => {
    if (!!dealersResponse && dealersResponse.length > 0) {
      const dealerMarkers = dealersResponse.map((dealer) => {
        let lat = -38.1437848;
        let lng = 145.1249061;
        const department =
          departmentsFilter.toLowerCase() === 'all departments'
            ? dealer.dealerDetails
            : dealer.dealerDetails.filter(
                (item) => item.departmentName === departmentsFilter
              );

        if (!!department[0]) {
          lat = parseFloat(department[0]?.geoLatitude);
          lng = parseFloat(department[0]?.geoLongitude);
        }

        return {
          position: { lat, lng },
          name: dealer.name,
          id: dealer.id,
        };
      });
      setMarkers(dealerMarkers);
    }
  };

  const handleSelectedDepartment = (option: Option | null) => {
    const selectedValue = option as Option;

    setSelectedDepartment(selectedValue);
    setDepartmentsFilter(selectedValue.value);
  };

  const panToMarker = (position) => {
    if (!!map && !!position) {
      map.panTo(position);
      map.setZoom(15);
    }
  };

  const handleMarkerClick = (marker) => {
    const newPosition = {
      lat: marker.latLng.lat(),
      lng: marker.latLng.lng(),
    };
    panToMarker(newPosition);
  };

  const showAll = (dealersList) => {
    let bounds = new google.maps.LatLngBounds();
    const maxItems = dealersList.length >= 4 ? 4 : dealersList.length;

    for (let i = 0; i < maxItems; i++) {
      const details = dealersList[i].dealerDetails[0];
      const latlng = new google.maps.LatLng(
        parseFloat(details.geoLatitude),
        parseFloat(details.geoLongitude)
      );

      if (bounds === null) {
        bounds = new google.maps.LatLngBounds(latlng, latlng);
      }

      bounds.extend(latlng);
    }

    markers?.forEach((marker) => {
      if (selectedDealer) {
        if (!!map && marker.name === selectedDealer.name) {
          const position = marker.position;
          if (!!position) map.setCenter(position);
        }
      }
    });

    map?.fitBounds(bounds);
  };

  const showDealers = (dealersList?) => {
    if (!!dealersList && dealersList.length > 0) {
      showAll(dealersList);
    } else {
      getDealersWithPostcode(suburb?.postcode);
    }
  };

  const handleSetMap = (mapInstance) => {
    setMap(mapInstance);
  };

  const clearSelectedDealer = () => {
    map?.setZoom(5.3);
    setSelectedDealer(null);
  };

  const mapOptions = {
    styles: mapStyles,
    mapTypeControl: false,
  };

  const defaultProps = {
    center: {
      lat: -26.355810601950772,
      lng: 127.60554765279083,
    },
    zoom: 5.3,
  };

  const clusterOptions = {
    gridSize: 60,
    maxZoom: 15,
    styles: clusterStyles,
  };

  return (
    // all these functions being pass as props is not a good approach.
    // we will refactor these in the future.
    <div className={styles.mapContainer}>
      {!!!selectedDealer ? (
        <SearchDealerPanel
          ref={searchPanelRef}
          departments={departments}
          selectedDepartment={selectedDepartment}
          handleSelectedDepartment={handleSelectedDepartment}
          setDealerBySuburb={setDealerBySuburb}
          onGetLocation={getDealersWithPostcode}
        />
      ) : (
        <SelectDealerPanel
          departments={departments}
          dealer={selectedDealer}
          departmentsFilter={departmentsFilter}
          selectedDepartmentOption={selectedDepartment}
          handleSelectedDepartment={handleSelectedDepartment}
          setDealerBySuburb={setDealerBySuburb}
          suburb={suburb}
          clearSelectedDealer={clearSelectedDealer}
          showDealers={() => {
            clearSelectedDealer();
            getDealersWithPostcode(currentPostcode).then(() => {
              searchPanelRef.current?.setCollapse(true);
            });
          }}
        />
      )}
      {!!selectedDealer && (
        <div className={styles.ShowAllContainer}>
          <div className={styles.ShowAllButton}>
            <div
              className={styles.BookAService}
              style={{ padding: '5px', width: '100%', gap: '15px' }}
              onClick={() => {
                clearSelectedDealer();
                getDealersWithPostcode(currentPostcode);
              }}
            >
              <SvgIcon
                type="location"
                size={1.2}
                fill="#1637a0"
                color="#FFFFFF"
              />
              Show Surrounding Retailers
            </div>
          </div>
        </div>
      )}

      <GoogleMap
        center={defaultProps.center}
        zoom={defaultProps.zoom}
        options={mapOptions}
        mapContainerStyle={{ height: '100%', width: '100%' }}
        onLoad={(mapLoaded) => {
          handleSetMap(mapLoaded);
        }}
      >
        <MarkerClusterer options={clusterOptions}>
          {(clusterer) => (
            <>
              {dealers.map((dealer: Dealer) => {
                let lat = -38.1437848;
                let lng = 145.1249061;
                const department =
                  departmentsFilter.toLowerCase() === 'all departments'
                    ? dealer.dealerDetails
                    : dealer.dealerDetails.filter(
                        (item) => item.departmentName === departmentsFilter
                      );

                if (!!department[0]) {
                  lat = parseFloat(department[0]?.geoLatitude);
                  lng = parseFloat(department[0]?.geoLongitude);
                }

                return (
                  <Marker
                    title={dealer.name}
                    key={dealer.id}
                    icon={
                      selectedDealer?.name === dealer.name
                        ? pinActiveIcon
                        : pinIcon
                    }
                    position={{ lat, lng }}
                    clusterer={clusterer}
                    onClick={(marker) => {
                      setSelectedDealer(dealer);
                      handleMarkerClick(marker);
                    }}
                  />
                );
              })}
            </>
          )}
        </MarkerClusterer>
      </GoogleMap>

      {isLoading && (
        <div className={styles.mapLoader}>
          <Loading height="100%" />
        </div>
      )}
    </div>
  );
};
