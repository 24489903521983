import React, { useState, useRef, useEffect } from 'react';
import { SvgIcon } from '@ui-elements';
import { SuburbModel } from '../models';
import Spinner from './Spinner';
import useOutsideClick from '../hook/useOutsideClick';
import styles from '../index.module.scss';

const PostCodeField = (props) => {
  const { handleSuburbSelect, postCode, handlePostCodeChange } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [suburbs, setSuburbs] = useState<SuburbModel[] | null>([]);
  const [showResult, setShowResult] = useState(false);
  const fieldRef = useRef<HTMLDivElement>(null);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (postCode.length > 2) {
      getSuburbs();
    } else {
      setShowResult(false);
    }
  }, [postCode]);

  const getSuburbs = () => {
    const url = `/api/location/?search=${postCode}`;
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (!!res && res.length > 0) {
          const suburbList = res.map((suburbItem) => {
            return {
              postcode: suburbItem.postcode,
              name: suburbItem.suburbName.replace(
                /\w\S*/g,
                (txt) =>
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
              ),
              state: suburbItem.stateAbbreviationName,
            };
          });
          setSuburbs(suburbList);
          setShowResult(true);
          setIsLoading(false);
          setErrorMsg('');
        } else {
          setShowResult(false);
          setIsLoading(false);
          setErrorMsg('No match found.');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    handlePostCodeChange(value);
  };

  const handleSubmit = (el: React.FormEvent<HTMLFormElement>) => {
    el.preventDefault();
    getSuburbs();
  };

  useOutsideClick(fieldRef, () => setShowResult(false));

  return (
    <div
      ref={fieldRef}
      className={styles.PostcodeFieldContainer}
      style={{ height: errorMsg ? `55px` : undefined }}
    >
      <form className={styles.PostcodeField} onSubmit={handleSubmit}>
        <input
          type="tel"
          placeholder="Enter Postcode"
          maxLength={4}
          onChange={(event) => {
            handleChange(event);
          }}
        />
        {!isLoading ? (
          <SvgIcon
            type="search"
            size={1.2}
            color="#1637A0"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getSuburbs();
            }}
          />
        ) : (
          <Spinner size={1.2} color="#1637A0" borderSize={'3px'} />
        )}
      </form>
      {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}
      <SuburbResult
        handleSuburbSelect={handleSuburbSelect}
        suburbList={suburbs}
        showResult={showResult}
      />
    </div>
  );
};

const SuburbResult = (props) => {
  const { handleSuburbSelect, suburbList, showResult } = props;
  const onSuburbClick = (suburbDetail) => {
    handleSuburbSelect(suburbDetail);
  };

  return (
    <>
      {showResult && !!suburbList && suburbList.length > 0 && (
        <div className={styles.SuburbResultsContainer}>
          {suburbList?.map((suburbItem: SuburbModel, index) => {
            return (
              <span key={index} onClick={() => onSuburbClick(suburbItem)}>
                {`${suburbItem.name}, ${suburbItem.postcode}`}
              </span>
            );
          })}
        </div>
      )}
    </>
  );
};

export default PostCodeField;
