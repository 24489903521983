import React from 'react';
import cn from 'classnames';
import { scroller } from 'react-scroll';
import { usePortals } from 'react-portal-hook';
import { useRecoilValue } from 'recoil';
import {
  DealerDepartmentList,
  DealerPageState,
  GetDeparmentQuery,
} from '../../store';
import {
  format12Hour,
  checkIsDepartmentOpen,
  getTodaysOpeningHour,
  getTodaysClosingHour,
  checkIsClosedAllWeek,
  isClosed,
} from '../../utils';
import { toTitleCase } from '~/common/utils';
import { Button, Rating, SvgIcon } from '~/common/components/ui-elements';
import { DealerDepartment } from '~/common/models';
import { DepartmentServices } from '../department-services';
import styles from './index.module.scss';

interface DealerDepartmentProps {
  totalRatings?: number;
  rating?: number;
}
export const DealerDepartments = React.memo<DealerDepartmentProps>(
  ({ rating, totalRatings }) => {
    const portalManager = usePortals();
    const [departmentId, setDepartmentId] = React.useState<string>('');

    const dealer = useRecoilValue(DealerPageState);
    const departments = useRecoilValue(DealerDepartmentList);
    const selectedDepartment = useRecoilValue(GetDeparmentQuery(departmentId));

    React.useEffect(() => {
      if (departments.length > 0) {
        setDepartmentId(departments[0].id);
      }
    }, []);

    const isOpenNow = React.useMemo(
      () => checkIsDepartmentOpen(selectedDepartment),
      [selectedDepartment, departmentId]
    );

    const isClosedAllWeek = React.useMemo(
      () => checkIsClosedAllWeek(selectedDepartment),
      [selectedDepartment, departmentId]
    );

    const currentOpeningHours = React.useMemo(
      () => getTodaysOpeningHour(selectedDepartment),
      [selectedDepartment]
    );

    const currentClosingHours = React.useMemo(
      () => getTodaysClosingHour(selectedDepartment),
      [selectedDepartment]
    );

    const showDepartmentServices = () => {
      portalManager.open((portal) => (
        <DepartmentServices
          closeModal={portal.close}
          title={`${dealer.dealerName} ${selectedDepartment?.departmentName}`}
          services={selectedDepartment?.serviceList ?? []}
        />
      ));
    };

    const scrollToGoogleReviews = () => {
      scroller.scrollTo('dealer-google-reivews', {
        smooth: true,
        offset: -85,
        ignoreCancelEvents: true,
      });
    };

    return (
      <div className={styles.dealerDepartments}>
        <div className={styles.dealerDetails}>
          <h1 className={styles.dealerName}>{dealer.dealerName}</h1>
          <div className={styles.dealerState}>
            <div className={cn(isOpenNow && styles.isOpenNow)}>
              {isOpenNow ? 'Open Now' : 'Closed'}
            </div>
            {!isClosedAllWeek && (
              <>
                <div>|</div>
                <div>
                  {isOpenNow ? (
                    <span>Until {currentClosingHours}</span>
                  ) : (
                    <span>Opens {currentOpeningHours}</span>
                  )}
                </div>
              </>
            )}
          </div>
          <div className={styles.googleReviewsWrapper}>
            <Rating rating={rating ?? 0.0} size="14px" />
            <p className={styles.googleReviews} onClick={scrollToGoogleReviews}>
              {totalRatings} Google Reviews
            </p>
          </div>

          <DepartmentTabs
            selectedDepartmentId={departmentId}
            departments={departments}
            onTabClick={setDepartmentId}
          />

          <div className={styles.departmentDetails}>
            <div className={styles.departmentField}>
              <div>
                <SvgIcon
                  type="phone"
                  size={0.8}
                  color="#1637A0"
                  fill="#1637A0"
                />
              </div>
              {selectedDepartment && (
                <div className={styles.departmentFieldValue}>
                  <span className={styles.hideMobile}>
                    {selectedDepartment.phone}
                  </span>
                  <a
                    className={styles.showMobile}
                    href={`tel:${selectedDepartment.phone}`}
                  >
                    {selectedDepartment.phone}
                  </a>
                </div>
              )}
            </div>

            <div className={styles.departmentField}>
              <div>
                <SvgIcon
                  type="location"
                  size={1.2}
                  color="#1637A0"
                  fill="#ffffff"
                />
              </div>
              {selectedDepartment && (
                <div
                  className={cn(styles.departmentFieldValue, styles.address)}
                >
                  <p>{selectedDepartment.address}</p>
                  <p>
                    <span>{toTitleCase(selectedDepartment.suburb)} </span>
                    <span>{dealer.state}, </span>
                    <span>{selectedDepartment.postCode}</span>
                  </p>
                  <a
                    className={styles.link}
                    href={`https://www.google.com/maps/dir/Current+Location/${selectedDepartment.address}+${selectedDepartment.postCode}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Directions
                  </a>
                </div>
              )}
            </div>

            <div className={styles.departmentField}>
              <div>
                <SvgIcon
                  type="cursor"
                  size={0.9}
                  color="#1637A0"
                  fill="#1637A0"
                />
              </div>
              {selectedDepartment && (
                <div className={styles.departmentFieldValue}>
                  <a
                    className={styles.link}
                    href={selectedDepartment.url}
                    style={{ color: '#323334' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {selectedDepartment.url}
                  </a>
                </div>
              )}
            </div>

            <div className={styles.departmentField}>
              <div>
                <SvgIcon
                  type="clock"
                  size={0.9}
                  color="#1637A0"
                  fill="#1637A0"
                />
              </div>
              {selectedDepartment && (
                <div className={styles.departmentFieldValue}>
                  <div className={styles.dealerState}>
                    <div className={cn(styles.hideIndicator)}>
                      {isOpenNow ? 'Open Now' : 'Closed'}
                    </div>
                    {!isClosedAllWeek && (
                      <>
                        <div>|</div>
                        <div>
                          {isOpenNow ? (
                            <span>Until {currentClosingHours}</span>
                          ) : (
                            <span>Opens {currentOpeningHours}</span>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <ScheduleHours
                    label="Mon - Fri"
                    openingHours={selectedDepartment.monFriOpen}
                    closingHours={selectedDepartment.monFriClose}
                  />
                  <ScheduleHours
                    label="Sat"
                    openingHours={selectedDepartment.satOpen}
                    closingHours={selectedDepartment.satClose}
                  />
                  <ScheduleHours
                    label="Sun"
                    openingHours={selectedDepartment.sunOpen}
                    closingHours={selectedDepartment.sunClose}
                  />
                </div>
              )}
            </div>

            {!!selectedDepartment && (
              <div className={styles.dealerDetailActionButtons}>
                {selectedDepartment.departmentName === 'Service' ? (
                  <Button
                    className={styles.actionButton}
                    href="/service-booking"
                    buttonWidth="full-mobile-and-tablet"
                  >
                    Book a Service
                  </Button>
                ) : (
                  selectedDepartment.departmentName !==
                    'Parts and Accessories' && (
                    <Button
                      className={styles.actionButton}
                      href="/book-test-drive"
                      buttonWidth="full-mobile-and-tablet"
                    >
                      Book a Test Drive
                    </Button>
                  )
                )}

                {selectedDepartment.serviceList.length > 0 && (
                  <Button
                    type="secondary"
                    buttonWidth="full-mobile-and-tablet"
                    className={styles.actionButton}
                    onClick={showDepartmentServices}
                  >
                    Available Services
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

interface DepartmentTabsProps {
  selectedDepartmentId: string;
  departments: DealerDepartment[];
  onTabClick: (id: string) => void;
}
const DepartmentTabs = React.memo<DepartmentTabsProps>(
  ({ selectedDepartmentId, departments, onTabClick }) => {
    const scrollContainer = React.useRef<HTMLUListElement>(null);

    const scrollLeft = () => {
      if (!!scrollContainer.current) scrollContainer.current.scrollLeft -= 100; // Adjust the scroll amount as needed
    };

    const scrollRight = () => {
      if (!!scrollContainer.current) scrollContainer.current.scrollLeft += 100; // Adjust the scroll amount as needed
    };

    const showScroll = React.useCallback(() => {
      if (!!scrollContainer.current) {
        return (
          scrollContainer.current.scrollWidth >
          scrollContainer.current.clientWidth
        );
      }

      return false;
    }, [scrollContainer]);

    return (
      <div
        className={cn(
          styles.departmentTabs,
          showScroll() && styles.tabShowScroll
        )}
      >
        <span
          className={cn(styles.tabArrow, styles.tabArrowLeft)}
          onClick={scrollLeft}
        >
          <SvgIcon size={0.8} type="chevronLeft" />
        </span>

        <ul ref={scrollContainer}>
          {departments.map((department) => (
            <li
              key={department.id}
              onClick={() => onTabClick(department.id)}
              className={cn(
                department.id === selectedDepartmentId && styles.isTabActive
              )}
            >
              {department.departmentName}
            </li>
          ))}
        </ul>
        <span
          className={cn(styles.tabArrow, styles.tabArrowRight)}
          onClick={scrollRight}
        >
          <SvgIcon size={0.8} type="chevronRight" />
        </span>
      </div>
    );
  }
);

interface ScheduleHoursProps {
  label: string;
  openingHours: string;
  closingHours: string;
}
export const ScheduleHours = React.memo<ScheduleHoursProps>(
  ({ label, openingHours, closingHours }) => {
    return (
      <div className={styles.schedule}>
        <span>{label}:</span>
        {isClosed(openingHours) ? (
          <span>CLOSED</span>
        ) : (
          <>
            <span>{format12Hour(openingHours)}</span>
            <span> - </span>
            <span>{format12Hour(closingHours)}</span>
          </>
        )}
      </div>
    );
  }
);
