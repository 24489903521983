import React from 'react';
import { DealerDetails } from '../models';
import { format12Hour, isClosed } from '~/pages/dealer/utils';

interface CurrentScheduleProps {
  dealerDetails: DealerDetails;
}
const CurrentSchedule = ({ dealerDetails }: CurrentScheduleProps) => {
  const currentDay = new Date().getDay();

  const monFriOpen = React.useMemo(
    () => format12Hour(dealerDetails.monFriOpen),
    [dealerDetails]
  );
  const monFriClose = React.useMemo(
    () => format12Hour(dealerDetails.monFriClose),
    [dealerDetails]
  );
  const satOpen = React.useMemo(
    () => format12Hour(dealerDetails.satOpen),
    [dealerDetails]
  );
  const satClose = React.useMemo(
    () => format12Hour(dealerDetails.satClose),
    [dealerDetails]
  );
  const sunOpen = React.useMemo(
    () => format12Hour(dealerDetails.sunOpen),
    [dealerDetails]
  );
  const sunClose = React.useMemo(
    () => format12Hour(dealerDetails.sunClose),
    [dealerDetails]
  );

  let isOpen = '';
  let schedule = '';
  if (currentDay >= 1 && currentDay <= 5) {
    if (isClosed(dealerDetails.monFriOpen)) {
      isOpen = 'Closed today';
    } else {
      isOpen = 'Open today';
      schedule = `${monFriOpen} - ${monFriClose}`;
    }
  } else if (currentDay === 6) {
    if (isClosed(dealerDetails.satOpen)) {
      isOpen = 'Closed today';
    } else {
      isOpen = 'Open today';
      schedule = `${satOpen} - ${satClose}`;
    }
  } else {
    if (isClosed(dealerDetails.sunOpen)) {
      isOpen = 'Closed today';
    } else {
      isOpen = 'Open today';
      schedule = `${sunOpen} - ${sunClose}`;
    }
  }

  return <>{`${isOpen} ${schedule}`}</>;
};

export default CurrentSchedule;
