import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  DealerDepartment,
  DealerHomepageData,
  DealerTeamMember,
} from '~/common/models';
import { FeatureContentProp } from '~/macros/feature-content';

export interface DealerState {
  dealerDetails: DealerDepartment[];
  state: string;
  bannerImages: string[];
  dealerName: string;
  discoverImage: string;
  discoverIntro: string;
  pageInfo?: DealerHomepageData;
  teamMembers: DealerTeamMember[];
  discoverMoreSection?: FeatureContentProp;
  googlePlaceId: any;
}

export const DealerPageState = atom<DealerState>({
  key: 'DealerPageState',
  default: {
    dealerDetails: [],
    state: '',
    bannerImages: [],
    dealerName: '',
    discoverImage: '',
    discoverIntro: '',
    pageInfo: undefined,
    teamMembers: [],
    discoverMoreSection: undefined,
    googlePlaceId: null,
  },
});

export const BannerImages = selector<string[]>({
  key: 'BannerImages',
  get: ({ get }) => get(DealerPageState).bannerImages,
});

export const DealerDepartmentList = selector<DealerDepartment[]>({
  key: 'DealerDepartments',
  get: ({ get }) => get(DealerPageState).dealerDetails,
});

export const GetDeparmentQuery = selectorFamily<
  DealerDepartment | undefined,
  string
>({
  key: 'GetDeparmentQuery',
  get:
    (id) =>
    ({ get }) => {
      const dealerDetails = get(DealerPageState).dealerDetails || [];
      return dealerDetails.find((x) => x.id === id);
    },
});
