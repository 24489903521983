import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const Spinner = ({ size, color, borderSize }) => {
  const { transform } = useSpring({
    loop: true,
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
    config: { duration: 1000 },
  });

  const getSize = () => {
    if (typeof size === 'string') return size;
    return `${size || 1}em`;
  };

  return (
    <animated.div
      style={{
        width: getSize(),
        height: getSize(),
        borderRadius: '50%',
        border: `${borderSize} solid rgba(204,204,204,0.0)`,
        borderLeftColor: color,
        transform,
      }}
    />
  );
};

export default Spinner;
