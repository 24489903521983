import dayjs from 'dayjs';
import { DealerDepartment } from '~/common/models';

const INPUT_FORMAT = 'YYYY-MM-DD HH:mm';

export function checkIsDepartmentOpen(department?: DealerDepartment) {
  if (!!department) {
    const currentDate = new Date();

    if (isWeekday(currentDate)) {
      return isOpen(department.monFriOpen, department.monFriClose);
    }

    if (isSaturday(currentDate)) {
      return isOpen(department.satOpen, department.satClose);
    }

    if (isSunday(currentDate)) {
      return isOpen(department.sunOpen, department.sunClose);
    }
  }

  return false;
}

export function checkIsClosedAllWeek(department?: DealerDepartment) {
  if (!!department) {
    if (
      isClosed(department.monFriOpen) &&
      isClosed(department.satOpen) &&
      isClosed(department.sunOpen)
    ) {
      console.log(true);
      return true;
    }
  }
  return false;
}

export function getTodaysOpeningHour(department?: DealerDepartment) {
  if (!!department) {
    const currentDate = new Date();

    if (isWeekday(currentDate)) {
      return format12Hour(department.monFriOpen);
    }

    if (isSaturday(currentDate)) {
      return format12Hour(department.satOpen);
    }

    if (isSunday(currentDate)) {
      return format12Hour(department.sunOpen);
    }
  }

  return '';
}

export function getTodaysClosingHour(department?: DealerDepartment) {
  if (!!department) {
    const currentDate = new Date();

    if (isWeekday(currentDate)) {
      return format12Hour(department.monFriClose);
    }

    if (isSaturday(currentDate)) {
      return format12Hour(department.satClose);
    }

    if (isSunday(currentDate)) {
      return format12Hour(department.sunClose);
    }
  }

  return '';
}

function isWeekday(date: Date) {
  const dayOfWeek = dayjs(date).day();
  return dayOfWeek >= 1 && dayOfWeek <= 5;
}

function isSaturday(date: Date) {
  const dayOfWeek = dayjs(date).day();
  return dayOfWeek === 6;
}

function isSunday(date: Date) {
  const dayOfWeek = dayjs(date).day();
  return dayOfWeek === 0;
}

function is24HourFormat(time: string) {
  const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return regex.test(time);
}

function convertTo24HourFormat(timeValue: string) {
  const time = timeValue.replace('.', ':');
  const parts = time.match(/^(\d{1,2}):(\d{2})\s?(am|pm)?$/i);
  if (!parts) {
    return 'Invalid Time Format';
  }

  let hours = parseInt(parts[1], 10);
  const minutes = parseInt(parts[2], 10);
  const ampm = parts[3] ? parts[3].toLowerCase() : null;

  if (ampm === 'pm' && hours < 12) {
    hours += 12;
  } else if (ampm === 'am' && hours === 12) {
    hours = 0;
  }

  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;
  return formattedTime;
}

function fixLegacyTimeFormat(time: string) {
  if (is24HourFormat(time)) return time;
  return convertTo24HourFormat(time);
}

export function format12Hour(time: string) {
  const currentTime = dayjs().format('YYYY-MM-DD');
  const currentDateTime = `${currentTime} ${fixLegacyTimeFormat(time)}`;
  if (currentDateTime.toLowerCase().includes('invalid')) {
    return '00:00';
  }
  const value = dayjs(currentDateTime, INPUT_FORMAT);
  return value.isValid() ? value.format('hh:mma') : '00:00';
}

export function isOpen(openingHours: string, closingHours: string) {
  const currentTime = dayjs();
  const currentDate = currentTime.format('YYYY-MM-DD');

  const openingDate = `${currentDate} ${fixLegacyTimeFormat(openingHours)}`;
  const openingTime = dayjs(openingDate, INPUT_FORMAT).format(
    'ddd, DD MMM YYYY hh:mm A'
  );

  const closingDate = `${currentDate} ${fixLegacyTimeFormat(closingHours)}`;
  const closingTime = dayjs(closingDate, INPUT_FORMAT).format(
    'ddd, DD MMM YYYY hh:mm A'
  );

  if (currentTime.isAfter(openingTime) && currentTime.isBefore(closingTime)) {
    return true;
  }

  return false;
}

export function isClosed(openingHours: string) {
  if (openingHours) {
    if (openingHours.toLowerCase().includes('closed')) {
      return true;
    } else if (format12Hour(openingHours) === '00:00') {
      return true;
    }
    return false;
  }
  return true;
}
