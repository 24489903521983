import React, { useState } from 'react';
import cn from 'classnames';
import { Button, Dropdown } from '~/common/components/ui-elements';
import { SvgIcon } from '@ui-elements';
import { Department } from '../models';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import { useUserLocation } from '~/common/hooks/use-userlocation';
import PostCodeField from './PostcodeField';
import digitalData from '~/common/services/data-layer-service';
import styles from '../index.module.scss';

export interface SearchDealerPanelRef {
  setCollapse: (isCollapse: boolean) => void;
}

const SearchDealerPanel = React.forwardRef<SearchDealerPanelRef, any>(
  (props, forwardRef) => {
    const {
      isLoading,
      getLocation,
      postcode: locPostcode,
    } = useUserLocation('AIzaSyAJ-SrDUJYZCpChPN1xqE8MZ13ZA87dCus');

    const {
      departments,
      selectedDepartment,
      handleSelectedDepartment,
      setDealerBySuburb,
      onGetLocation,
    } = props;

    const { isMobile } = useIsMobile();
    const [postCode, setPostCode] = useState(0);
    const [visible, setVisible] = useState(true);
    const options = [{ id: 1, name: 'All Departments' }, ...departments].map(
      (department: Department) => {
        return {
          label: department.name,
          value: department.name,
        };
      }
    );

    React.useImperativeHandle(
      forwardRef,
      () => ({
        setCollapse: (v) => setVisible(() => !v),
      }),
      []
    );

    const handleSuburbSelect = (suburbDetail) => {
      setDealerBySuburb(suburbDetail);
    };

    const handlePostCodeChange = (value) => {
      setPostCode(value);
    };

    const [showPostcodeField, setShowPostcodeField] = useState(false);

    React.useEffect(() => {
      if (!!locPostcode) {
        onGetLocation(locPostcode).then(() => {
          setVisible(false);
        });
      }
    }, [locPostcode]);

    const handleEnterPostcode = () => {
      setShowPostcodeField(true);
      digitalData.push({
        event: '_formNavigate',
        form: {
          name: 'find a dealer',
          stage: 'enter postcode click',
        },
      });
    };

    const handleShowDealers = () => {
      getLocation();
      digitalData.push({
        event: '_formNavigate',
        form: {
          name: 'find a dealer',
          state: 'show dealers near me',
        },
      });
    };

    return (
      <div
        className={cn(
          styles.SearchContainer,
          isMobile() && {
            [styles.SearchContainerCollapsable]: true,
            [styles.SearchContainerCollapsable_Open]: visible,
          }
        )}
      >
        <>
          <div className={styles.SearchContainerHeader}>
            <span className={styles.FindADealerText}>Find a Retailer</span>
            {isMobile() && (
              <SvgIcon
                type={visible ? 'chevronUp' : 'chevronDown'}
                onClick={() => setVisible(!visible)}
              />
            )}
          </div>
          <div>
            <span>I&apos;m looking to find a retailer for</span>
            <Dropdown
              options={options}
              value={selectedDepartment}
              bordered={false}
              onChange={handleSelectedDepartment}
              className={styles.DepartmentDropdown}
            />
          </div>
          <div className={styles.CTAContainer}>
            {showPostcodeField ? (
              <PostCodeField
                handleSuburbSelect={handleSuburbSelect}
                handlePostCodeChange={handlePostCodeChange}
                postCode={postCode}
              />
            ) : (
              <div
                className={styles.BookAService}
                onClick={handleEnterPostcode}
              >
                Enter Postcode
              </div>
            )}

            <Button
              buttonSize="medium"
              buttonWidth="full-width"
              type="secondary"
              loading={isLoading}
              onClick={handleShowDealers}
            >
              <SvgIcon
                type="location"
                size={1.2}
                fill="#FFFFFF"
                color={isLoading ? '#9bb4dd' : '#1637a0'}
              />
              <div>Show retailers near me</div>
            </Button>
          </div>
        </>
      </div>
    );
  }
);

export default SearchDealerPanel;
