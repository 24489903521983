import React from 'react';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import { Modal, SvgIcon } from '~/common/components/ui-elements';
import { DealerService } from '~/common/models';
import styles from './index.module.scss';

interface DepartmentServicesProps {
  title: string;
  services: DealerService[];
  closeModal: () => void;
}
export const DepartmentServices = React.memo<DepartmentServicesProps>(
  ({ closeModal, title, services }) => {
    return (
      <Modal closeModal={closeModal} modalCloseButtonClass={styles.closeButton}>
        <div className={styles.servicesModal}>
          <div className={styles.header}>
            <div className={styles.nameIcon}>
              <SvgIcon type="wrench" size={'20px'} color="#FFFFFF" />
            </div>
            <p className={styles.dealerName}>{title}</p>
          </div>

          <div className={styles.servicesContent}>
            <p>Available Services</p>
            <div className={styles.services}>
              <Carousel
                swipeable
                centerMode
                autoPlay={false}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={false}
                showIndicators={false}
                centerSlidePercentage={47}
                showArrows
                className={styles.serviceCarousel}
                renderArrowPrev={(onClickHandler, hasPrev) => (
                  <div
                    onClick={hasPrev ? onClickHandler : undefined}
                    className={cn(
                      styles.customArrow,
                      hasPrev && styles.arrowEnable,
                      services.length <= 2 && styles.arrowHidden
                    )}
                    style={{ left: '-5px' }}
                  >
                    <SvgIcon type="chevronLeft" size={1.5} />
                  </div>
                )}
                renderArrowNext={(onClickHandler, hasNext) => (
                  <div
                    onClick={hasNext ? onClickHandler : undefined}
                    className={cn(
                      styles.customArrow,
                      hasNext && styles.arrowEnable,
                      services.length <= 2 && styles.arrowHidden
                    )}
                    style={{ right: '-5px' }}
                  >
                    <SvgIcon type="chevronRight" size={1.5} />
                  </div>
                )}
              >
                {services.map((service, index) => (
                  <ServiceType key={index} service={service} />
                ))}
              </Carousel>

              <div className={styles.serviceMobile}>
                {services.map((service, index) => (
                  <ServiceType key={index} service={service} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

interface ServiceTypeProp {
  service: DealerService;
}
const ServiceType = React.memo<ServiceTypeProp>(({ service }) => {
  return (
    <div className={styles.serviceType}>
      <div className={styles.serviceTypeName}>{service.serviceType}</div>
      <ul>
        {service.services.map((serviceItem, index) => (
          <li key={index}>
            <span>&bull;</span>
            <span>{serviceItem.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
});
