import { useState } from 'react';

type Coords = {
  latitude?: number;
  longitude?: number;
};

export const useUserLocation = (apiKey: string) => {
  const [coords, setCoords] = useState<Coords>();
  const [postcode, setPostcode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      setIsLoading(() => true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoords({ latitude, longitude });
          getAddressFromCoordinates(latitude, longitude);
        },
        (e) => {
          setError(e.message);
          setIsLoading(() => false);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
      setIsLoading(() => false);
    }
  };

  const getAddressFromCoordinates = (latitude: number, longitude: number) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    // const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=-37.793424575001055,144.8814710851796&key=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(() => false);

        const results = data.results;
        if (results.length > 0) {
          const addressComponents = results[0].address_components;
          let foundPostcode = null;

          addressComponents.forEach((component) => {
            if (component.types.includes('postal_code')) {
              foundPostcode = component.long_name;
            }
          });

          if (!!foundPostcode) {
            setError(null);
            setPostcode(() => foundPostcode);

            return;
          }
        }

        setError('No results found');
      })
      .catch((e) => {
        setIsLoading(() => false);
        setError(e.message);
      });
  };

  return {
    coords,
    postcode,
    getLocation,
    isLoading,
    error,
  };
};
