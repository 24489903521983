const clusterStyles = [
  {
    width: 54,
    height: 53,
    url: '/scripts/ContentMedia/images/icon_map-cluster-small.png',
    textColor: 'white',
    textSize: 12,
  },
  {
    width: 54,
    height: 53,
    url: '/scripts/ContentMedia/images/icon_map-cluster-large.png',
    textColor: 'white',
    textSize: 14,
  },
  {
    width: 54,
    height: 53,
    url: '/scripts/ContentMedia/images/icon_map-cluster-large.png',
    textColor: 'white',
    textSize: 16,
  },
];

export default clusterStyles;
